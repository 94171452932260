import _get from 'lodash/get'
import moment from 'moment'
import Actions from '../components/Actions'
import RowButton from '@/components/buttons/Row'

export const methods = {
  determineRows() {
    const dateFormat = 'LL'

    const list = this.list
    if (!Array.isArray(list)) {
      return []
    }

    this.rows = list.map(entry => {
      const address = _get(entry, 'address', null)

      var isClosed = _get(entry, 'isClosed', false)

      var date = _get(entry, 'createdDate', '')
      try {
        date = moment(date).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      const id = _get(entry, 'violationID', null)

      let stage = ''
      stage = _get(entry, 'lastStepName', null)

      return {
        id,
        type: _get(entry, 'violationTypeName', null),
        owner: _get(entry, 'ownerName', null),
        address,
        stage,
        status: isClosed === true ? 'Closed' : 'Open',
        date: {
          date,
          format: dateFormat
        },
        actions: {
          component: Actions,
          props: {
            detail: id,
            isHidden: _get(entry, 'isClosed', false),
            openEscalator: () => {
              this.$refs.escalator.open({
                id
              })
            }
          }
        },
        button: {
          component: RowButton,
          props: {
            to: {
              name: 'newconversation',
              params: {
                violationid: _get(entry, 'violationID', 0),
                violationownerid: _get(entry, 'ownerID', 0),
                violationlabel: `Add Homeowner - ${_get(entry, 'ownerName', '')}`
              }
            },
            text: 'Ask a Question'
          }
        }
      }
    })
  },

  openAdder() {
    this.$refs.adder.open()
  }
}
