import _cloneDeep from 'lodash/cloneDeep'
import { workOrderList } from '@/services/WorkOrders/store'
import { violationStore } from '@/services/ViolationIssue/Violation/store'

export const methods = {
  optionSelected(option) {
    this.selected = option
    this.formData.address = ''
  },

  filterList: function(name) {
    this.formData.address = ''

    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    } else {
      // no update occurred
      return
    }

    this.isFiltering = true

    if (this.name === '') {
      this.data = _cloneDeep(this.fullList)
    }

    this.data = this.fullList.filter(owner => {
      try {
        if (owner.label.toLowerCase().includes(this.name.toLowerCase())) {
          return true
        }
      } catch (x) {
        console.error(x)
      }

      return false
    })

    this.isFiltering = false
  },

  async getAddressChoice() {
    await violationStore.dispatch('getAddressChoiceList').then(({ list }) => {
      if (list) {
        this.addressChoiceList = list
      }
    })
  },

  async getAccessToken() {
    await workOrderList
      .dispatch('getAccessToken', {
        credentialsName: 'google-maps-api-key'
      })
      .then(({ result }) => {
        if (result) {
          this.accessToken = result.credentialValue
        }
      })
  },

  async setCurrentLocation() {
    navigator.geolocation.getCurrentPosition(position => {
      this.formData.latitude = position.coords.latitude
      this.formData.longitude = position.coords.longitude
    })
  },

  async returnMarkerAddress(address) {
    this.formData.address = address
  },

  hideShowMap() {
    console.debug('in hideShowMap...')
    this.hideShowMapFlag = !this.hideShowMapFlag
    if (this.hideShowMapFlag == true) {
      this.hideShowMapText = 'Hide Map'
    } else {
      this.hideShowMapText = 'Show Map'
    }
  },

  async reloadParentImages() {
    if (this.isDebug === true) console.debug('reloadParentImages...')
  },

  onFocus() {
    console.debug('onFocus()...')

    if (this.formData && this.formData.address && this.formData.address.length > 0) {
      this.googleAddress = true
      this.query = null
    } else {
      this.googleAddress = false
    }

    console.debug('this.googleAddress=' + this.googleAddress)
  }
}
