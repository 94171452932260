<template>
  <div>
    <SimpleButton
      class="button is-info is-light is-small is-outline is-rounded"
      :style="{ margin: '4px' }"
      :to="{
        path: `violationtabs/${detail}/violationDetails#tab`,
        params: {
          id: detail
        }
      }"
    >
      Details
    </SimpleButton>
    <span v-if="isHidden === false">
      <b-button
        class="button is-info is-light is-small is-outline is-rounded"
        :style="{ margin: '4px' }"
        type="is-primary"
        rounded
        @click="openEscalator"
        >Escalate</b-button
      >
    </span>
  </div>
</template>
<script>
import SimpleButton from '@/components/buttons/Simple'

export default {
  components: {
    SimpleButton
  },
  props: {
    detail: Number,
    isHidden: {
      default: false,
      type: Boolean
    },
    openEscalator: Function
  },
  methods: {}
}
</script>
