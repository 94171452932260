<template>
  <div class="py-2 sm:py-4 flex flex-wrap">
    <div class="w-full sm:px-4">
      <span class="inline-block text-lg my-4"
        >To confirm the report, please review the details and press "save".</span
      >

      <div :style="{ padding: '30px 0' }">
        <div
          :style="{
            borderTop: `1px solid ${color[9]}`,
            margin: '5px 0'
          }"
        ></div>

        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Type</div>
          <div :style="Object.assign({}, styles.listItem)">{{ details.typeName }}</div>
        </div>

        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Action</div>
          <div :style="Object.assign({}, styles.listItem)">{{ details.actionName }}</div>
        </div>

        <div
          :style="{
            borderTop: `1px solid ${color[9]}`,
            margin: '5px 0'
          }"
        ></div>

        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Owner</div>
          <div :style="Object.assign({}, styles.listItem)">{{ details.ownerName }}</div>
        </div>
        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Address</div>
          <div :style="Object.assign({}, styles.listItem)">{{ details.ownerAddress }}</div>
        </div>

        <div
          :style="{
            borderTop: `1px solid ${color[9]}`,
            margin: '5px 0'
          }"
        ></div>

        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Observed By</div>
          <div :style="Object.assign({}, styles.listItem)">{{ details.observedBy }}</div>
        </div>
        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Observation Date</div>
          <div :style="Object.assign({}, styles.listItem)">{{ details.observationDate }}</div>
        </div>
        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Description</div>
          <div :style="Object.assign({}, styles.listItem)">{{ details.description }}</div>
        </div>
        <div :style="Object.assign({}, styles.listRow)">
          <div :style="Object.assign({}, styles.listLabel)">Pictures</div>
          <div :style="Object.assign({}, styles.listItem)">
            <figure
              v-for="(preview, index) in previews"
              :key="index"
              tabindex="0"
              :aria-label="'photo ' + (index + 1)"
              :style="{
                position: 'relative',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',

                margin: '4px',

                width: '70px',
                height: '70px',

                overflow: 'hidden',
                borderRadius: '6px',

                boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
              }"
            >
              <transition name="fade">
                <img
                  :src="preview"
                  :style="{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',

                    flexShrink: '0',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }"
                />
              </transition>
            </figure>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: row; justify-content: end">
        <b-button
          type="is-primary"
          size="is-medium"
          :style="{ marginRight: '20px' }"
          @click="preview"
          >Preview</b-button
        >

        <b-button type="is-primary" size="is-medium" @click="save">Save</b-button>
      </div>
      <div :style="{ height: '70px' }"></div>
    </div>
  </div>
</template>

<script>
import { beforeDestroy } from './keys/beforeDestroy'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { props } from './keys/props'
import { watch } from './keys/watch'

export default {
  name: 'ReviewViolation',

  beforeDestroy,
  data,
  computed,
  methods,
  mounted,
  props,
  watch
}
</script>

<style></style>
