/*
    import { getOwnersList } from '@/services/Roster/Unit/sequences/getOwnersList';

    const { list } = await getOwnersList ({
        hoaID
    });
 */
//
import { getUnitList } from '../List'
import _get from 'lodash/get'
import { getString } from '@/utilities/get/string'
//
export async function getOwnersList({ hoaID }) {
  const message = 'The list of owners could not be retrieved'

  try {
    const { list, successful } = await getUnitList({
      hoaID
    })
    if (successful) {
      return {
        list: list
          .map(prop => {
            let value = _get(prop, ['primaryOwner', 'ownerID'], '')

            const name = getString(prop, ['primaryOwner', 'name'], '')
            const address = getString(prop, ['address', 'addressOne'], '')

            return {
              data: prop,
              value,
              label: `${name} - ${address}`
            }
          })
          .filter(owner => {
            if (typeof owner.value !== 'number' || owner.value < 1) {
              console.debug(`ownerID value for owner isn't valid`, { owner, list })
              return false
            }

            return true
          }),
        successful: true,
        message: ''
      }
    }
  } catch (x) {
    console.error('An exception occurred while attempting to get a parsed list of owners')
    console.error(x)
  }

  return {
    successful: false,
    message,
    list: []
  }
}
