<template>
  <div class="py-2 sm:py-4 flex flex-wrap mt-4">
    <div class="w-full sm:w-2/5">
      <div
        class="relative w-full text-cool-gray-400 focus-within:text-cool-gray-600 border-b border-gray-400"
      >
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            />
          </svg>
        </div>

        <input
          v-model="search"
          class="block w-full h-full pl-8 pr-3 py-2 rounded-md bg-transparent text-cool-gray-900 placeholder-cool-gray-500 focus:outline-none focus:placeholder-cool-gray-400 sm:text-lg"
          placeholder="Search violation types..."
          type="search"
        />
      </div>

      <div class="overflow-auto h-64 sm:h-127">
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="py-1 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5"
        >
          <li
            :id="`listbox-item-${index}`"
            v-for="(item, index) in filteredList"
            :key="item.violationTypeID"
            role="option"
            :class="
              `text-gray-900 cursor-pointer select-none relative py-2 pl-8 pr-4 ${
                active === index ? `bg-blue-200` : ``
              }`
            "
            @click="selectedType(item, index)"
          >
            <span :class="`font-normal truncate ${active === index ? `` : ``}`">
              {{ item.name }}
            </span>

            <span
              :class="
                `absolute inset-y-0 left-0 flex items-center pl-1.5 ${
                  active === index ? `text-white` : ``
                }`
              "
              v-if="active === index"
            >
              <!-- Heroicon name: check -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="w-full sm:w-3/5 sm:pl-8 pt-4 sm:pt-0">
      <h2 class="text-xl mb-4">Select date of occurrence</h2>
      <DatePicker v-model="observationDate" ariaLabel="date" />
    </div>

    <div class="pt-5">
      <b-field label="Observed By">
        <b-input maxlength="255" aria-required="true" type="text" v-model="observedBy"></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { props } from './keys/props'
import { watch } from './keys/watch'
import DatePicker from '@/components/inputs/DatePicker'

export default {
  components: {
    DatePicker
  },

  mounted() {
    const authUser = _get(this, ['$store', 'getters', 'user/authUser'], null)
    this.observedBy = authUser.name
  },

  computed,
  data,
  methods,
  props,
  watch
}
</script>

<style></style>
