/*
  For reporting a new one

  {
    "violationActionID": 0,
    "ownerID": 0,
    "hoaID": 0,
    "violationTypeID": 0,
    "violationEscalationID": 0,
    "formLetterID": 0,
    "violationNote": "string",
    "isClosed": true,
    "observationDate": "2021-08-30T18:40:19.581Z",
    "observedBy": "string",
    "observationDescription": "string",
    "createdDate": "2021-08-30T18:40:19.581Z",
    "modifiedDate": "2021-08-30T18:40:19.581Z",
    "deletedDate": "2021-08-30T18:40:19.581Z",
    "uploadedDocumentIds": [
      0
    ]
  }
*/

/*
  import { reportNewViolation } from '@/services/ViolationIssue/Violation/CreateViolationActionStepOccurrence';

  // for example:
    const { successful } = await reportNewViolation ({
      json: {
        hoaID,
        ownerID,

        violationTypeID,
        violationActionID,
        violationEscalationID,
        formLetterID,

        violationNote: "vio note",
        isClosed: false,
        observationDescription: "vio description",

        observationDate: "2021-08-09T21:31:51.958Z",
        observedBy: "string",
        uploadedDocumentIds: []
      }
    });
    if (!successful) {
      notifyProblem ('A problem occurred and the violation could not be reported');
      return;
    }
*/

//
import kms from '@/services/kms'
//
export async function reportNewViolation({ kms: kms_ = kms, json } = {}) {
  const message = 'A problem occurred and the violation could not be reported'

  try {
    const result = await kms_.post(
      `/ViolationIssue/Violation/CreateViolationActionStepOccurrence`,
      json
    )

    return {
      message: '',
      result,
      successful: true
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    message,
    result: null,
    successful: false
  }
}
