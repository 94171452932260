//
//
import _get from 'lodash/get'
//
import { notifyProblem } from '@/services/notify'
//
import { vReporterStore } from '@/pages/Management/violations/Reporter/store.js'
import { violationListStore } from '@/pages/Management/violations/Main/store.js'
//
import { parseDate } from '@/utilities/Date/parse'
import parseAddress from '@/utilities/address/parse'
import { getArray } from '@/utilities/get/Array'
import { getString } from '@/utilities/get/string'
//
export const methods = {
  async updateData() {
    const state = vReporterStore.state

    const actions = getArray(vReporterStore, ['state', 'actions'], [])
    let action = actions.find(action => {
      try {
        return action.violationActionID === state.violationActionID
      } catch (x) {
        console.error(x)
      }
      return false
    })

    const actionName = getString(action, 'name', '')

    this.details = {
      typeName: _get(vReporterStore, ['state', 'selectedType', 'name'], null),
      actionName,
      //
      ownerName: getString(state, ['owner', 'data', 'primaryOwner', 'name'], ''),
      ownerAddress: parseAddress({
        address: _get(state, ['owner', 'data', 'address'], '')
      }),
      //
      observedBy: getString(state, ['observedBy'], ''),
      observationDate: parseDate(_get(vReporterStore, ['state', 'observationDate'], null), 'LL'),
      description: _get(state, ['observationDescription'], null)
    }

    this.name = _get(vReporterStore, ['state', 'selectedType', 'name'], null)
    this.observationDescription = _get(vReporterStore, ['state', 'observationDescription'], null)
    this.observationDate = parseDate(_get(vReporterStore, ['state', 'observationDate'], null), 'LL')

    const previews = _get(vReporterStore, ['state', 'previews'], [])
    if (Array.isArray(previews)) {
      this.previews = previews
    }

    this.isOccurrence = _get(vReporterStore, ['state', 'isOccurrence'], false)
    this.isNew = _get(vReporterStore, ['state', 'isNew'], false)

    this.nameOfType = _get(vReporterStore, ['state', 'selectedType', 'name'], '')
  },
  async reportNew() {
    vReporterStore.commit('loading', true)

    const { successful } = await vReporterStore.dispatch('reportNew')
    if (!successful) {
      notifyProblem('A problem occurred, and the report could not be added successfully.')
      vReporterStore.commit('loading', false)
      return
    }

    // refreshes the main list
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    await violationListStore.dispatch('refreshList', {
      hoaID
    })

    vReporterStore.commit('loading', false)
    vReporterStore.commit('closeModal')
  },
  //
  async save() {
    this.reportNew()
  },
  async preview() {
    vReporterStore.commit('loading', true)

    const { result } = await vReporterStore.dispatch('generateLetterPreview')

    vReporterStore.commit('loading', false)

    var newWindow = window.open()

    if (!newWindow.document) {
      notifyProblem('Unable to preview letter - please make sure popups are not blocked.')
      return
    }

    newWindow.document.body.innerHTML = result
  }
}
