import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
import { hoaUtility } from '@/services/Hoa/store'
import { notifyError } from '@/services/notify'

export const methods = {
  async open() {
    await violationReporterStore.dispatch('erase')

    this.isOpen = true
    this.step = 0
    this.loading = false
    this.checkPreventions()
  },
  checkPreventions() {
    const isOwnerSlide = this.step === 0
    const isTypeSelector = this.step === 1
    const isPictures = this.step === 2
    const isNotePad = this.step === 3
    const isActionSelector = this.step === 4

    this.prevPrevented = false

    // is the last slide
    if (this.step === this.totalSteps - 1) {
      this.nextPrevented = true
    } else {
      this.nextPrevented = false
    }

    /*
      slide 0
    */
    if (isOwnerSlide) {
      this.prevPrevented = true

      // if no owner is selected,
      // prevent next with message
      if (this.ownerSelected === true) {
        this.nextPrevented = false
        this.nextToolTip = ''
      } else {
        this.nextPrevented = true
        this.nextToolTip = 'Please select an owner, or map address, to continue.'
      }
    } else if (isTypeSelector) {
      if (this.typeSelected === true) {
        this.nextPrevented = false
        this.nextToolTip = ''
      } else {
        this.nextPrevented = true
        this.nextToolTip = 'Please select a type to continue.'
      }
    } else if (isPictures) {
      this.prevPrevented = false
      // this.nextToolTip = ""
    } else if (isNotePad) {
      if (this.noteAdded === true) {
        this.nextPrevented = false
        this.nextToolTip = ''
      } else {
        this.nextPrevented = true
        this.nextToolTip = 'Please add a note to continue.'
      }
    } else if (isActionSelector) {
      if (this.actionSelected) {
        this.nextPrevented = false
        this.nextToolTip = ''
      } else {
        this.nextPrevented = true
        this.nextToolTip = 'Please select an action to continue.'
      }
    } else {
      this.prevPrevented = false
    }
  },

  async checkMapAddress() {
    await hoaUtility.dispatch('googleAddressExistsInSystem', {
      hoaID: this.hoaId,
      address: this.stepAddress,
      done: async ({ result }) => {
        console.debug('address compare=' + JSON.stringify(result))
        if (result.validatedAddress && result.validatedAddress.address) {
          //set ownerID into session state
          await violationReporterStore.dispatch('setOwner', {
            hoaId: this.hoaId,
            label: result.validatedAddress.address,
            value: result.validatedAddress.ownerID,
            data: result.validatedAddress.ownerID
          })
          this.isMapAddressValid = true
        } else {
          this.ownerSelected = false
          this.nextPrevented = true
          this.nextToolTip = 'Please select an owner to continue.'
          notifyError(
            'Address not recognized as a valid property address. Please select another property from the map.'
          )
          this.isMapAddressValid = false
        }
      }
    })
  },

  async nextStep() {
    if (this.isMapAddress === true) {
      await this.checkMapAddress()
      console.debug('this.isMapAddressValid=' + this.isMapAddressValid)

      if (this.isMapAddressValid === false) {
        console.debug('returning')
        return
      }
    }

    if (this.step <= this.totalSteps - 1) {
      this.step++
    }

    this.checkPreventions()
  },
  prevStep() {
    if (this.step >= 1) {
      this.step--
    }

    this.checkPreventions()
  },

  checkAddressOnNextStep(address, isFromAddress) {
    console.debug('address from parent=' + address)

    if (isFromAddress === true) {
      this.isMapAddress = true
      this.stepAddress = address
      this.ownerSelected = false
      this.nextPrevented = false
      this.nextToolTip = ''
    } else {
      this.isMapAddress = false
      this.stepAddress = ''
    }
  }
}
