import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'

import _get from 'lodash/get'

export function mounted() {
  this.unsubViolationAdderStore = violationReporterStore.subscribe((mutation, state) => {
    const mType = mutation.type

    if (mType === 'setOwner') {
      this.ownerSelected = true
      this.checkPreventions()
    } else if (mType === 'clearOwner') {
      this.ownerSelected = false
      this.checkPreventions()
    } else if (mType === 'modify') {
      const selectedTypeName = _get(state, ['selectedType', 'name'], null)

      if (typeof selectedTypeName === 'string' && selectedTypeName.length >= 1) {
        this.typeSelected = true
      } else {
        this.typeSelected = false
      }

      const observationDescription = _get(state, ['observationDescription'], null)
      if (typeof observationDescription === 'string' && observationDescription.length >= 1) {
        this.noteAdded = true
      } else {
        this.noteAdded = false
      }

      const violationActionID = _get(state, ['violationActionID'], null)
      if (['string', 'number'].includes(typeof violationActionID)) {
        this.actionSelected = true
      } else {
        this.actionSelected = false
      }

      this.checkPreventions()
    } else if (mType === 'loading') {
      this.loading = state.loading
    } else if (mType === 'closeModal') {
      this.isOpen = false
    }
  })

  this.checkPreventions()
}
