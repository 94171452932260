//
//
import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
import _get from 'lodash/get'
//
export const watch = {
  async query() {
    if (this.selected === null) {
      await violationReporterStore.dispatch('clearOwner')
      return
    }

    const { label, value, data } = this.selected
    const hoaId = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    this.formData.address = ''

    await violationReporterStore.dispatch('setOwner', {
      hoaId,
      label,
      value,
      data
    })

    return
  },
  async 'formData.address'() {
    const isFromAddress =
      this.formData.addressMethod && this.formData.addressMethod === 'fromMap' ? true : false
    this.checkAddressOnNextStep(this.formData.address, isFromAddress)
  }
}
