/*
  import { vReporterStore } from '@/pages/Management/violations/Reporter/store.js'

  this.unsubViolationAdderStore = vReporterStore.subscribe ((mutation, state) => {
    console.log(mutation.type)
    console.log(mutation.payload)

  });
*/

import _get from 'lodash/get'
import Vue from 'vue'
import Vuex from 'vuex'
//
import { notifyProblem } from '@/services/notify'
//
import { getViolationTypeList } from '@/services/Violation/ViolationType/List'
import { getVActionList } from '@/services/Violation/ViolationAction/List'
//
import { StoreMultiplePhotos } from '@/services/ViolationIssue/ViolationOccurrencePhoto/sequences/StoreMultiplePhotos'
import { reportNewViolation } from '@/services/ViolationIssue/Violation/CreateViolationActionStepOccurrence'
import { listViolations } from '@/services/ViolationIssue/Violation/List'
import { reportOccurence } from '@/services/ViolationIssue/Violation/CreateViolationReportOccurrence'
import { generateViolationLetterHTMLPreview } from '@/services/ViolationIssue/Violation/GenerateViolationLetterHTMLPreview'
//
const presetState = () => {
  return {
    observedBy: null,
    owner: null,
    selectedType: null,
    actions: [],
    //
    isNew: true
  }
}

Vue.use(Vuex)
const violationReporterStore = new Vuex.Store({
  /* eslint-disable no-empty-pattern */
  actions: {
    async loadActionsList({ state, commit }, { violationEscalationID }) {
      const { list: actionsList, successful, message } = await getVActionList({
        hoaID: state.hoaId,
        escalationID: violationEscalationID
      })
      if (!successful) {
        notifyProblem(message)
        return
      } else {
        commit('modify', {
          actions: actionsList
        })
      }
    },

    /*
      await violationReporterStore.dispatch ('modify', {});
    */
    async modify({ commit }, payload) {
      commit('modify', payload)
    },
    async erase({ commit }) {
      commit('erase')
    },

    /*
      declare that the violation is a new one

      await violationReporterStore.dispatch ('setAsNew');
    */
    async setAsNew({ commit }) {
      commit('setAsNew')
    },

    /*
      declare that the violation is an occurence of an existing one
    */
    async setAsOccurence({ commit }, { existingViolationID }) {
      commit('setAsOccurence', {
        existingViolationID
      })
    },

    /*
      await violationReporterStore.dispatch ('setAsEscalation')
    */
    async setAsEscalation({ commit }, { existingViolationID }) {
      commit('setAsEscalation', {
        existingViolationID
      })
    },

    /*
      await violationReporterStore.dispatch ('setOwner', {
        label:
        value:
      });
    */
    async setOwner({ commit }, { label, value, data, hoaId }) {
      commit('setOwner', { label, value, data, hoaId })
    },
    async clearOwner({ commit }) {
      commit('clearOwner')
    },

    async loadTypes({ state }) {
      const { list, successful, message } = await getViolationTypeList({
        hoaID: state.hoaId
      })
      if (!successful) {
        console.error(message)
        return {
          list: null
        }
      }

      return { list }
    },
    async setSelectedType({ commit }, { selectedType }) {
      commit('setSelectedType', { selectedType })
    },

    /*
      load the violations list for a particular owner (via ownerID)
    */
    async loadPrevious({ state }) {
      const ownerID = _get(state, ['owner', 'value'], null)
      if (typeof ownerID !== 'number') {
        notifyProblem('The previous violations could not be loaded.')
        return
      }

      // for example:
      const { successful, message, list } = await listViolations({
        hoaID: state.hoaId,
        ownerID
      })
      if (!successful) {
        notifyProblem(message)
        return
      }

      return {
        list
      }
    },

    async reportNew({ state }) {
      const pics = state.pics
      const hoaID = state.hoaId

      let uploadedDocumentIds = []
      let picUploadProblems = []
      if (Array.isArray(pics) && pics.length >= 1) {
        const { uploads, problems } = await StoreMultiplePhotos({
          hoaID,
          photos: pics
        })

        if (problems && problems !== undefined) {
          console.debug('problems=' + JSON.stringify(problems))
        }

        uploadedDocumentIds = uploads
        picUploadProblems = problems
      }

      const { successful } = await reportNewViolation({
        json: {
          hoaID: state.hoaId,
          ownerID: state.owner.value,
          //
          violationTypeID: state.selectedType.violationTypeID,
          violationActionID: state.violationActionID,
          violationEscalationID: state.violationEscalationID,
          //
          violationNote: '',
          isClosed: false,
          //
          observedBy: state.observedBy,
          observationDate: state.observationDate,
          observationDescription: state.observationDescription,
          //
          uploadedDocumentIds
        }
      })
      return {
        successful,
        picUploadProblems
      }
    },
    async reportOccurence({ state }) {
      const pics = state.pics
      const hoaID = state.hoaId

      const { uploads: uploadedDocumentIds, problems } = await StoreMultiplePhotos({
        hoaID,
        photos: pics
      })

      if (problems && problems !== undefined) {
        console.debug('problems=' + JSON.stringify(problems))
      }

      const { successful } = await reportOccurence({
        json: {
          existingViolationID: state.existingViolationID,
          //
          violationNote: '',
          isClosed: false,
          //
          observedBy: state.observedBy,
          observationDate: state.observationDate,
          observationDescription: state.observationDescription,
          //
          uploadedDocumentIds
        }
      })

      return {
        successful
      }
    },
    async generateLetterPreview({ state }) {
      const pics = state.pics
      const hoaID = state.hoaId

      let uploadedDocumentIds = []
      if (Array.isArray(pics) && pics.length >= 1) {
        const { uploads, problems } = await StoreMultiplePhotos({
          hoaID,
          photos: pics
        })

        if (problems && problems !== undefined) {
          console.debug('problems=' + JSON.stringify(problems))
        }

        uploadedDocumentIds = uploads
      }

      const result = await generateViolationLetterHTMLPreview({
        json: {
          ownerID: state.owner.value,
          //
          violationTypeID: state.selectedType.violationTypeID,
          violationActionID: state.violationActionID,
          //
          observationDate: state.observationDate,
          description: state.observationDescription,
          //
          photoDocumentIDs: uploadedDocumentIds
        }
      })
      return result
    }
  },
  /* eslint-enable no-empty-pattern */

  getters: {},

  mutations: {
    erase(state) {
      const preset = presetState()

      for (const key in state) {
        if (preset[key] !== undefined) {
          state[key] = preset[key]
        } else {
          state[key] = null
        }
      }
    },
    setOwner(state, { label, value, data, hoaId }) {
      state.owner = { label, value, data }
      state.hoaId = hoaId
    },
    clearOwner(state) {
      state.owner = null
    },
    setSelectedType(state, { selectedType }) {
      state.selectedType = selectedType
    },
    modify(state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    },
    loading(state, toggle) {
      state.loading = toggle
    },
    closeModal() {},
    setAsNew(state) {
      state.isNew = true
    }
  },
  state: presetState()
})

const vReporterStore = violationReporterStore

export { violationReporterStore, vReporterStore }
