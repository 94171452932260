import _get from 'lodash/get'
import { getOwnersList } from '@/services/Roster/Unit/sequences/getOwnersList'

export async function mounted() {
  this.loading = true

  this.getAccessToken()

  this.getAddressChoice()

  const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

  const { list } = await getOwnersList({
    hoaID
  })

  this.data = list
  this.fullList = list

  this.loading = false
}
