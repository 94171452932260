export function data() {
  return {
    list: [],
    rows: [],
    occurrences: 0,
    toggle: false,
    filters: {
      show: true
    },
    region: {
      title: this.$t('violationsList.title'),
      role: 'main',
      ariaLabel: this.$t('violationsList.title')
    },
    columns: [
      {
        field: 'id',
        numeric: true,

        label: 'ID',
        aria: 'Violation ID',
        width: '5%',
        sortable: true,
        searchable: true
      },
      {
        field: 'type',

        label: 'Type',
        aria: 'Lot number',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'owner',

        label: 'Owner',
        aria: 'Owner',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'address',

        label: 'Address',
        aria: 'Address',
        width: '15%',
        sortable: true,
        searchable: true
      },

      {
        field: 'stage',
        // numeric: true,

        label: 'Stage',
        aria: 'Stage',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'status',

        label: 'Status',
        aria: 'Status',
        width: '5%',
        sortable: true,
        searchable: true
      },
      {
        field: 'date',
        date: true,

        label: 'Reported On',
        aria: 'Date',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'actions',

        label: '',
        aria: '',
        width: '20%'
      }
    ]
  }
}
