export function data() {
  return {
    loading: true,
    isDebug: true,
    isFiltering: false,
    data: [],
    fullList: [],
    query: null,
    selected: null,
    addressChoiceList: null,
    formData: {
      latitude: 0,
      longitude: 0,
      address: '',
      addressMethod: ''
    },
    hideShowMapFlag: null,
    accessToken: '',
    googleAddress: false
  }
}
