/*
  example:
    import { getViolationTypeList } from '@/services/Violation/ViolationType/List';

    const { list, successful, message } = await getViolationTypeList ({
      hoaID
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import _get from 'lodash/get'
import kms from '@/services/kms'
//
export async function getViolationTypeList({ hoaID, kms: kms_ = kms } = {}) {
  const message = 'The violation type list could not be retrieved.'

  try {
    const result = await kms_.get(`/Violation/ViolationType/List`, {
      params: {
        hoaID
      }
    })

    return {
      list: _get(result, 'results', []),
      successful: true,
      message
    }
  } catch (x) {
    console.error(x)
  }

  return {
    successful: false,
    list: null,
    message
  }
}

///////
