//
//
import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
//
export const watch = {
  async slideIsActive(active) {
    if (active === true) {
      const { list } = await violationReporterStore.dispatch('loadTypes')

      this.typesList = list
      this.loading = false
    }
  },
  async observationDate(observationDate) {
    await violationReporterStore.dispatch('modify', {
      observationDate
    })
  },
  async observedBy(observedBy) {
    await violationReporterStore.dispatch('modify', {
      observedBy
    })
  }
}
