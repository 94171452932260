import moment from 'moment'

export function data() {
  return {
    selectedViolation: null,
    active: null,
    search: null,
    observationDate: moment(new Date()).toISOString(),
    observedBy: null,
    typesList: []
  }
}
