<template>
  <div class="py-2 sm:py-4 flex flex-wrap mt-4">
    <h2 class="text-xl mb-1">
      Violation notes
    </h2>
    <div class="w-full sm:px-4">
      <textarea
        class="resize border rounded focus:outline-none focus:shadow-outline w-full h-64 p-2 mt-4"
        placeholder="Add details about the violation..."
        v-model="observationDescription"
        rules="max:8000"
        maxlength="8000"
      />
    </div>
  </div>
</template>

<script>
import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
//
export default {
  name: 'ViolationNotes',

  data() {
    return {
      observationDescription: null
    }
  },

  mounted() {
    this.unsubViolationAdderStore = violationReporterStore.subscribe((mutation, state) => {
      this.observationDescription = state.observationDescription
    })
  },

  watch: {
    async observationDescription() {
      await violationReporterStore.dispatch('modify', {
        observationDescription: this.observationDescription
      })
      // this.$store.dispatch('violations/setDTO', {
      //   observationDescription: this.observationDescription
      // })
    }
  }
}
</script>

<style></style>
