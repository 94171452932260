<template>
  <!-- scroll area -->
  <section class="flex flex-col">
    <b-field class="file justify-center items-center">
      <b-upload
        v-model="file"
        multiple
        drag-drop
        accept="image/png, image/jpeg, image/jpg"
        class="border-dashed border-2 border-gray-400 flex flex-col"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large" />
            </p>
            <p>
              Drop a PNG, JPG, or JPEG file here, or click to select a file from your computer.
            </p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <ul id="gallery" class="flex flex-1 flex-wrap-m-1">
      <li v-show="!previews.length" class="h-full w-full text-center flex flex-col">
        <img
          class="mx-auto w-32"
          src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
          alt="no data"
        />
        <span class="text-small text-gray-500">No files selected</span>
      </li>
      <li
        v-for="(image, index) in previews"
        :key="index"
        class="p-1 flex w-1/4 h-32 justify-center items-center"
      >
        <div class="relative w-full h-full bg-cover" :style="`background-image: url(${image});`">
          <button class="delete is-small" type="button" @click="deleteDropFile(index)" />
          <label class="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 z-10 p-2">
            <span class="text-black font-small bg-white">{{ file[index].name }}</span>
          </label>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
//
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'
//
export default {
  data,
  methods,
  watch
}
</script>

<style lang="scss">
.violation-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}
</style>
