/*
  For reporting another occurrence

  {
    "existingViolationID": 0,

    "violationNote": "string",
    "isClosed": true,

    "observationDate": "2021-08-30T22:31:32.373Z",
    "observedBy": "string",
    "observationDescription": "string",

    "createdDate": "2021-08-30T22:31:32.373Z",
    "modifiedDate": "2021-08-30T22:31:32.373Z",
    "deletedDate": "2021-08-30T22:31:32.373Z",
    "uploadedDocumentIds": [
      0
    ]
  }

  import { reportOccurence } from '@/services/ViolationIssue/Violation/CreateViolationReportOccurrence';

  await reportOccurence ({
    json: {
      existingViolationID: 0,

      violationNote: "a note",
      isClosed: false,

      observationDate: "2021-08-30T22:31:32.373Z",
      observedBy: "",
      observationDescription: "",

      uploadedDocumentIds: []
    }
  })
*/

//
import kms from '@/services/kms'
//
export async function reportOccurence({ kms: kms_ = kms, json } = {}) {
  let message = `The escalation list could not be retrieved.`

  try {
    await kms_.post(`/ViolationIssue/Violation/CreateViolationReportOccurrence`, json)

    return {
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message
  }
}
