//
import { notifyProblem } from '@/services/notify'
//
import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
//
export const methods = {
  async deleteDropFile(index) {
    this.file.splice(index, 1)

    const pics = this.file

    const previews = this.previews
    this.previews.splice(index, 1)

    await violationReporterStore.dispatch('modify', {
      previews,
      pics
    })
  },

  async updatePictures() {
    const pics = this.file
    if (!Array.isArray(pics)) {
      notifyProblem('A problem occurred and the photos could not be uploaded.')
      return
    }

    ////////////////////////////////
    //  Store image previews
    ////////////////////////////////

    this.previews = this.file.map(file => {
      return URL.createObjectURL(file)
    })

    await violationReporterStore.dispatch('modify', {
      previews: this.previews,
      pics
    })
  }
}
