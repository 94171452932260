<template>
  <PageContent :title="$t('violationsList.title')">
    <div class="pb-5">
      <b-button
        @click="openAdder()"
        size="is-small"
        type="is-primary"
        rounded
        :style="{ marginRight: '12px' }"
      >
        Report New
      </b-button>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :styles="{ tableWrapper: { height: 'calc(100vh - 250px)' } }"
      readMoreColumn=""
    />

    <Reporter ref="adder" />
    <Escalator ref="escalator" />

    <div>
      <Modal :toggle.sync="toggle">
        <Creation />
      </Modal>
    </div>
  </PageContent>
</template>

<script>
// Components
import Modal from '@/components/Modal'
import ModernTable from '@/components/tables/Modern/Table'
import PageContent from '@/containers/PageContent'
import Escalator from '../Escalator'
import Reporter from '../Reporter'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'

export default {
  components: {
    Reporter,
    Escalator,
    Modal,
    PageContent,
    ModernTable
  },

  data,
  methods,
  mounted,
  watch: {
    toggle() {
      console.debug('in toggle...')
    }
  },

  i18n: {
    messages: {
      en: {
        violationsList: {
          title: 'Violations'
        }
      }
    }
  }
}
</script>

<style></style>
