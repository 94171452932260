var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 sm:py-4 flex flex-wrap"},[_c('div',{staticClass:"w-full sm:px-4"},[_c('span',{staticClass:"inline-block text-lg my-4"},[_vm._v("To confirm the report, please review the details and press \"save\".")]),_c('div',{style:({ padding: '30px 0' })},[_c('div',{style:({
          borderTop: ("1px solid " + (_vm.color[9])),
          margin: '5px 0'
        })}),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Type")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.typeName))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Action")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.actionName))])]),_c('div',{style:({
          borderTop: ("1px solid " + (_vm.color[9])),
          margin: '5px 0'
        })}),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Owner")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.ownerName))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Address")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.ownerAddress))])]),_c('div',{style:({
          borderTop: ("1px solid " + (_vm.color[9])),
          margin: '5px 0'
        })}),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Observed By")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.observedBy))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Observation Date")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.observationDate))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Description")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},[_vm._v(_vm._s(_vm.details.description))])]),_c('div',{style:(Object.assign({}, _vm.styles.listRow))},[_c('div',{style:(Object.assign({}, _vm.styles.listLabel))},[_vm._v("Pictures")]),_c('div',{style:(Object.assign({}, _vm.styles.listItem))},_vm._l((_vm.previews),function(preview,index){return _c('figure',{key:index,style:({
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',

              margin: '4px',

              width: '70px',
              height: '70px',

              overflow: 'hidden',
              borderRadius: '6px',

              boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
            }),attrs:{"tabindex":"0","aria-label":'photo ' + (index + 1)}},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{style:({
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%)',

                  flexShrink: '0',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain'
                }),attrs:{"src":preview}})])],1)}),0)])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"end"}},[_c('b-button',{style:({ marginRight: '20px' }),attrs:{"type":"is-primary","size":"is-medium"},on:{"click":_vm.preview}},[_vm._v("Preview")]),_c('b-button',{attrs:{"type":"is-primary","size":"is-medium"},on:{"click":_vm.save}},[_vm._v("Save")])],1),_c('div',{style:({ height: '70px' })})])])}
var staticRenderFns = []

export { render, staticRenderFns }