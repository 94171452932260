export function data() {
  return {
    loading: false,
    isOpen: false,
    step: 0,
    totalSteps: 6,
    toggle: false,
    nextPrevented: false,
    nextToolTip: '',
    prevPrevented: true,
    prevToolTip: '',
    ownerSelected: false,
    typeSelected: false,
    noteAdded: false,
    actionSelected: false,
    isNew: true,
    isMapAddress: false,
    isMapAddressValid: false,
    stepAddress: ''
  }
}
