/*
  import { violationStore } from '@/services/ViolationIssue/Violation/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
//import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getAddressChoiceList({}) {
    try {
      let choices = [
        { value: 'fromAddress', label: 'Select from Address' },
        { value: 'fromMap', label: 'Select from Map' }
      ]

      choices.unshift({ value: '', label: '<-Select Address Type->' })

      if (isDebug == true) console.debug('getAddressChoiceList=' + JSON.stringify(choices))

      return {
        list: choices
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the address choice list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const violationStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
