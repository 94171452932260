<template>
  <div>
    <div>
      <b-select
        v-model="formData.addressMethod"
        required="true"
        placeholder="Select an Address Method"
      >
        <option v-for="option in addressChoiceList" :value="option.value" :key="option.value">
          {{ option.label }}
        </option>
      </b-select>
    </div>
    <div
      class="pb-5 pt-5"
      v-if="formData.addressMethod && formData.addressMethod === 'fromAddress'"
    >
      <b-field label="Owner">
        <b-autocomplete
          vr-owner-select
          v-model="query"
          :data="data"
          :loading="isFiltering"
          :open-on-focus="true"
          :keep-first="true"
          field="label"
          icon="magnify"
          placeholder="e.g. First Name or Last Name or Address"
          @typing="filterList"
          @select="optionSelected"
        >
          <template #empty>No results found</template>
        </b-autocomplete>
      </b-field>
    </div>
    <div class="pt-5" v-if="formData.addressMethod && formData.addressMethod === 'fromMap'">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <div class="is-12">
              <valid-input
                style="width:50px !important;"
                name="address"
                label="Address"
                type="text"
                vid="Address"
                placeholder="Address"
                spellcheck="true"
                aria-label="Address"
                rules="max:8000"
                class="is-small"
                v-model="formData.address"
                @input="onFocus"
                :disabled="true"
                :readonly="true"
              />
            </div>
          </form>
        </transition>
      </ValidationObserver>
      <div>
        <input type="hidden" v-model="formData.latitude" /><input
          type="hidden"
          v-model="formData.longitude"
        />
      </div>
      <div class="pt-3">
        <GoogleMap
          mapStyle="satellite"
          :accessToken="accessToken"
          :longitude="Number.parseFloat(formData && formData.longitude ? formData.longitude : 0)"
          :latitude="Number.parseFloat(formData && formData.latitude ? formData.latitude : 0)"
          :defaultLongitude="
            Number.parseFloat(formData && formData.longitude ? formData.longitude : 0)
          "
          :defaultLatitude="
            Number.parseFloat(formData && formData.latitude ? formData.latitude : 0)
          "
          :zoomLevel="parseInt(19)"
          :reloadParent="reloadParentImages"
          :returnMarkerAddress="returnMarkerAddress"
          :showUpload="true"
          :allowGeocodingOnMarker="true"
        />
      </div>
    </div>
    <b-loading v-model="loading"></b-loading>
  </div>
</template>

<script>
import GoogleMap from '@/components/googlemaps'
import { ValidationObserver } from 'vee-validate'
import ValidInput from '@/components/inputs/ValidInput'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

export default {
  data,
  methods,
  mounted,
  watch,

  props: {
    checkAddressOnNextStep: Function
  },

  components: {
    GoogleMap,
    ValidInput,
    ValidationObserver
  }
}
</script>

<style></style>
