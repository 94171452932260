//
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import { violationListStore } from '@/pages/Management/violations/Main/store.js'
//
export async function mounted() {
  const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
  const { list } = await violationListStore.dispatch('refreshList', {
    hoaID
  })

  this.list = _orderBy(list, 'createdDate', 'desc')
  this.determineRows()

  this.unsubViolationListStore = violationListStore.subscribe((mutation, state) => {
    const mType = mutation.type

    if (mType === 'refreshList') {
      this.list = _orderBy(state.lists[hoaID], 'createdDate', 'desc')
      this.determineRows()
    }
  })
}
