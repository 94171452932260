var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 sm:py-4 flex flex-wrap mt-4"},[_c('div',[_c('h2',{staticClass:"text-xl mb-4"},[_vm._v(" The next letter in the violation escalation has been pre-selected. If you would like to escalate to a different letter, select from the options below. ")]),_c('div',_vm._l((_vm.actions),function(item,index){return _c('button',{key:index,class:"select-none relative inline-block w-1/2",style:({ position: 'relative' }),on:{"click":function($event){return _vm.selectedType(item, index)}}},[_c('div',{style:({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'white',
            padding: '8px',
            borderRadius: '4px',
            left: '17px'
          })},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{class:("cursor-pointer m-2 p-4 border border-blue-800 rounded text-gray-900 " + (_vm.active === index ? "bg-blue-200" : "bg-blue-400"))},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),(_vm.actions.length === 0)?_c('div',[_vm._v(" There currently are not any escalation actions setup for violation type \""+_vm._s(_vm.typeName)+"\". ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }