import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
import _get from 'lodash/get'

export const methods = {
  async selectedType(selectedType, index) {
    this.active = index
    const observationDate = this.observationDate

    const violationEscalationID = _get(
      selectedType,
      ['violationEscalation', 'violationEscalationID'],
      null
    )

    const descriptionTemplate = _get(selectedType, ['descriptionTemplate'], null)

    await violationReporterStore.dispatch('loadActionsList', {
      violationEscalationID
    })

    await violationReporterStore.dispatch('modify', {
      selectedType,
      observationDate,
      observationDescription: descriptionTemplate
    })
  }
}
