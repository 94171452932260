<template>
  <div id="vio-adder" vio-reporter>
    <Modal
      width="700"
      height="700"
      :toggle.sync="isOpen"
      :styles="{
        modalContent: { height: '800px' },
        modal: { maxHeight: '90vh' }
      }"
    >
      <template v-slot:header>Violation Reporter</template>

      <template v-slot:footer>
        <div :style="{ float: 'right' }">
          <b-tooltip :active="prevToolTip.length >= 1" :label="prevToolTip" position="is-right">
            <b-button
              vio-reporter-prev-button
              icon-left="chevron-left"
              size="is-medium"
              :style="{ marginRight: '8px' }"
              @click="prevStep"
              :disabled="prevPrevented"
            ></b-button>
          </b-tooltip>

          <b-tooltip :active="nextToolTip.length >= 1" :label="nextToolTip" position="is-left">
            <b-button
              vio-reporter-next-button
              icon-left="chevron-right"
              size="is-medium"
              @click="nextStep"
              :disabled="nextPrevented"
            ></b-button>
          </b-tooltip>
        </div>
      </template>

      <div>
        <transition name="fade" mode="out-in" appear>
          <b-steps v-model="step" :has-navigation="false" label-position="bottom" size="is-small">
            <b-step-item label="Owner">
              <Owner ref="owner" :checkAddressOnNextStep="checkAddressOnNextStep" owner-step />
            </b-step-item>

            <b-step-item label="Type">
              <Occurrence :slideIsActive="step === 1" occurrence-step />
            </b-step-item>

            <b-step-item label="Pictures">
              <ImageUpload pictures-step />
            </b-step-item>

            <b-step-item label="Notes">
              <Notes notes-step />
            </b-step-item>

            <b-step-item label="Action">
              <Stage stage-step />
            </b-step-item>

            <b-step-item label="Review">
              <Review :active="step === 5" review-step />
            </b-step-item>
          </b-steps>
        </transition>
      </div>

      <b-loading v-model="loading"></b-loading>
    </Modal>
  </div>
</template>

<style lang="scss">
#vio-adder {
  .b-steps .steps + .step-content {
    padding-top: 30px !important;
  }
}
</style>

<script>
// Steps
import Owner from './components/Owner'
import Occurrence from './components/Occurence'
import ImageUpload from './components/ImageUpload'
import Notes from './components/Notes'
import Stage from './components/Stage'
import Review from './components/Review'
import Modal from '@/components/Modal'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
import { mapState } from 'vuex'

export default {
  components: {
    Modal,
    Owner,
    Occurrence,
    ImageUpload,
    Notes,
    Stage,
    Review
  },
  data,
  watch,
  methods,
  mounted,
  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  }
}
</script>
