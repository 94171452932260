import _get from 'lodash/get'

export function data() {
  const theme = _get(this, ['$store', 'state', 'theme'], {})

  return {
    color: theme.color,
    //
    isDebug: true,
    loading: false,
    saved: false,
    //
    isOccurrence: false,
    isNew: false,
    //
    nameOfType: null,
    observationDate: null,
    observationDescription: null,
    previews: [],
    //
    styles: {
      listRow: {
        display: 'flex'
      },
      listLabel: {
        display: 'inline-block',
        flexBasis: '186px',
        color: 'rgb(130, 130, 130)'
      },
      listItem: {
        display: 'inline-block',
        flexBasis: `calc(100% - 186px)`
      }
    },
    //
    details: {}
  }
}
