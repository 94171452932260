<template>
  <div row-button v-if="(visible && visible == true) || visible == undefined" :style="styles">
    <router-link v-if="model === 'router'" :class="buttonClass" :to="to">
      {{ text }}
    </router-link>
    <button v-else-if="model === 'text'" :class="buttonClass" :aria-label="text" @click="clicked">
      {{ text }}
    </button>
  </div>
</template>

<script>
/*
	import RowButton from '@/components/buttons/Row'

  <RowButton
    text="Details"
    :to="{
      path: `architecturaltabs/${id}/architecturalRequestDetails#tab`,
      params: {
        id
      }
    }"
  />

  props: {
    
  }
  
*/

/*
	<RowButton
		@click=""
	>Edit</RowButton>
	<RowButton
		@click=""
	>Delete</RowButton>
*/

export default {
  props: {
    model: {
      type: String,
      default: 'router'
    },
    text: {
      type: String,
      default: 'Details'
    },
    onClick: {
      type: Function,
      default() {}
    },
    to: Object,
    visible: {
      Boolean,
      default: true
    },
    styles: {
      type: Object,
      default() {
        return {}
      }
    },
    size: {
      type: String,
      //
      // 'is-small'
      default: 'is-small'
    },
    type: {
      type: String,
      //
      // 'is-outline'
      default: 'is-info is-light'
    }
  },
  data: function() {
    return {}
  },
  methods: {
    clicked() {
      this.$emit('click')
      this.onClick()
    }
  },
  computed: {
    buttonClass() {
      const size = this.size
      const buttonType = this.type

      return [
        'button',
        'is-rounded',

        ...(size.length >= 1 ? [size] : []),
        ...(buttonType.length >= 1 ? [buttonType] : [])
      ].join(' ')
    }
  }
}
</script>
