<template>
  <div class="py-2 sm:py-4 flex flex-wrap mt-4">
    <div>
      <h2 class="text-xl mb-4">
        The next letter in the violation escalation has been pre-selected. If you would like to
        escalate to a different letter, select from the options below.
      </h2>

      <div>
        <button
          :class="`select-none relative inline-block w-1/2`"
          :style="{ position: 'relative' }"
          v-for="(item, index) in actions"
          :key="index"
          @click="selectedType(item, index)"
        >
          <div
            :style="{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'white',
              padding: '8px',
              borderRadius: '4px',
              left: '17px'
            }"
          >
            {{ index + 1 }}
          </div>

          <div
            :class="
              `cursor-pointer m-2 p-4 border border-blue-800 rounded text-gray-900 ${
                active === index ? `bg-blue-200` : `bg-blue-400`
              }`
            "
          >
            {{ item.name }}
          </div>
        </button>
      </div>

      <div v-if="actions.length === 0">
        There currently are not any escalation actions setup for violation type "{{ typeName }}".
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'
//
import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
//
import { methods } from './keys/methods'
//
export default {
  data() {
    return {
      active: null,
      escalationTypes: [],
      actions: [],
      typeName: ''
    }
  },

  mounted() {
    this.unsubViolationAdderStore = violationReporterStore.subscribe((mutation, state) => {
      this.actions = state.actions
      this.typeName = _get(state, ['selectedType', 'name'], '')

      if (this.active === null && this.actions.length >= 1) {
        this.active = 0
        this.declareAction()
      }
    })
  },

  methods
}
</script>

<style></style>
