import _get from 'lodash/get'
import { notifyProblem } from '@/services/notify'
import { violationReporterStore } from '@/pages/Management/violations/Reporter/store.js'
//
export const methods = {
  async selectedType(escalationType, index) {
    this.active = index

    this.declareAction()
  },

  async declareAction() {
    const escalationType = this.actions[this.active]

    const violationEscalationID = _get(escalationType, 'violationEscalationID', null)
    const violationActionID = _get(escalationType, 'violationActionID', null)
    const formLetterID = _get(escalationType, 'formLetterID', null)

    if (!['string', 'number'].includes(typeof violationActionID)) {
      notifyProblem('A problem occurred and the action selected could not be recorded.')
      return
    }

    if (!['string', 'number'].includes(typeof violationEscalationID)) {
      notifyProblem('A problem occurred and the action selected could not be recorded.')
      return
    }

    await violationReporterStore.dispatch('modify', {
      violationEscalationID,
      violationActionID,
      formLetterID
    })
  }
}
